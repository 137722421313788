// Responsive Carousel
.wrapper-carousel {
  padding:10px 45px 0px 45px !important;
  border:1px solid @fontLighter;
  margin-bottom:20px;
}
.wrapper-carousel .carousel {
  margin-bottom:0;
}
.wrapper-carousel .row {
  margin-left: 0px !important;
}
.wrapper-carousel .carousel-control.right {
  right: -45px;
}
.wrapper-carousel .carousel-control.left {
  left: -45px;
}

.carousel-inner > .item > a.carouselCaption {
    display: block;
      line-height: 1;
        height:330px;
}
.carousel-caption .thumbnails {
    margin-bottom: 0px;
}
.foldervisual .carousel-caption p {
        margin-right:auto;
}
.carousel-caption .thumbnail {
    border: 1px solid #666;
}
.thumbnail .caption {
    text-align:center;
        }
.carousel-caption .thumbnails > li {
    margin-bottom: 0px;
}

