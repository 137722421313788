//images 

img-summary {
	 margin-bottom: 10px;
}

span.captioned-img {display:inline-block;}
.captioned span img {margin:0 !important; max-width:initial !important;} 
// responsive images
.image-inline,
.image-left,
.image-right {
//  display: block;
  max-width: 100%;
  height: auto;
  }
.image-caption a {
	color:@font;
	padding:5px;
	}
.image-left {
  float: left;
  margin: 0em 1em 0.5em 0;
}
.image-right {
  float: right !important;
  margin: 0em 0 0.5em 0.5em !important;
}
.image-inline {
  float: none !important;
}
.image-caption {background:@blueLighter;padding:10px 5px}
