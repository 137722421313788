.ms-tabs-template {
    max-width: 1140px;
    margin: 0 auto;
    font-family: "DGUVMetaWeb-Normal", sans-serif;
}

.ms-tabs-vertical-template {
	max-width: 1065px;
}

.ms-tabs-template .ms-thumb-frame h3 {
	font-weight: 400;
	font-size: 18px;
	margin:0;
	font-family: "DGUVMetaWeb-Normal", sans-serif;
}

.ms-tabs-template .ms-thumb-frame p {
	font-size:10pt;
	margin: 4px 0;
}

.ms-tabs-template .ms-thumb-frame-selected h3{
	color:#d40f14; /*red*/
	font-family: "DGUVMetaWeb-Normal", sans-serif;
}

 /* Layers */
.ms-layer.video-title {
    font-family: "DGUVMetaWeb-Normal",sans-serif;
    color: white;
    font-weight: 600;
    font-size: 42px;
    margin:0px;
    letter-spacing:0px;
}

.ms-layer.video-title.video-top-title {
    font-size: 16px;
    color: white;
}

.ms-layer.video-title.video-sub-title {
    font-size: 22px;
    font-weight: 300;
}
