
.portletNewsletterSubscriber {
	background:white;
	padding:5px;
	.portletHeader { 
    		margin: 5px 0 !important;
    		border-bottom: 1px solid #ebebeb;
		
		a {
		padding-right: 5px;
    		padding-left: 5px;
    		font-weight: bold;
    		line-height: 1.4em;
    		color: #09c;
		font-size: 17px;
    		font-family: "DGUVMetaWeb-Normal", sans-serif;
		}	
	}
}

