/* override the css toolbar.css for ploneFormGen QuickEdit*/
.pfg-form label {
	text-align:left !important;
}

.field {
    margin-bottom: 1.2em;
}
.formHelp {
	color:#888 !important;
	display:block;
}
input.context,
input.standalone {
	background: @blue;
    color: white;
    margin: 6px;
    padding: 6px;
}
form.rowlike {margin:12px;}
select[multiple], select[size] {
  min-width: 120px;
}
// form
input[type="text"], input[type="password"] {
        background:#fff;
}
.navbar-search input[type="text"] {
        border: 1px solid @blue;
}
.portlet input[type="submit"] {
	margin:20px 0 0 20px;
}

.portlet input[type="password"],
.portlet input[type="text"] {
        margin-left:10px;
        width:80%;
}
input[type="submit"] {
        margin: 0 0 0 5px;
}
input[type="radio"],
input[type="checkbox"] {
        margin:-3px 5px 0 0;
        }
textarea.no-width,
input.no-width {width:auto;}
.template-manage-portlets select {
        width:172px;
        }
form {
margin-bottom:10px;
        }
ul.formTabs {
background-color: #d4d7dd;
border: 0.3em solid #d4d7dd;
display: block;
        }
li.formTab {
display: inline;
margin-right:0.25em;
}
li.formTab a {
display: inline-block;
line-height: 26px;
padding: 0 0.75em;
text-decoration: none;
border: none;
margin: 0;
}
li.formTab a.selected {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #d4d7dd;
  border-bottom-color: transparent;
  cursor: default;
}
.formQuestion {
        display:inline;
        }
.formQuestion.label {
 white-space:normal;
  display:block;
  text-align:left !important;
  padding:2em 1em 0.5em 0 !important;
  font-size:100% !important;
}
label {display:inline;}
#pfg-fieldwrapper {margin-bottom:12px;}
.formControls button {
        margin-top:5px;
        }
.ArchetypesFileWidget input[type="file"] {
        padding: 0;
        background-color:transparent;
        border:0;
        line-height:1em;
       }
.ArchetypesSelectionWidget label {
 display:inline-block;
}
.label {
  color:inherit;
  text-shadow:none;
  background-color: transparent;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
        }
.label:empty, .badge:empty {
  display: block;
}
#formfield-form-searchplz label {
        display:inline;
        }
#formfield-form-searchplz {
        float:left;
        }
#actionsView  {
        padding-top:20px;
        }
.required:after {
   content: "*";
   color:red;
}
