#login_form #login-form {

  .field {
    text-align: left;
    input {
       background-color: white;
    }
    label {
      text-align: left;
    }
  }
  .formControls {
    text-align: left;
    margin-left: 0;
    input {
      margin-left: 0;
    }
  }
}
