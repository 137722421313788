.ms-tabs-vertical-template {

    max-width: 1146px; // BBB: how can we use the bootstrap vars here?
    margin-top:15px;
   .ms-thumb-list {
       right: -205px !important;
   }

}

.ms-layer.video-title {
  @media (max-width: 1200px) {
    line-height:21px !important;
    font-size:14px !important;
  }
}

.ms-tabs-template .ms-thumb-frame h3 {
  @media (max-width: @screen-xs-max) {
    font-size:16px;
  }
  }
