.template-ploneglossary_view { 
h1 {color:@red;}
#glossary dt {
 &:before {content: "\f0da";padding-right: 8px;font-family: 'FontAwesome';font-size: 14px;}
 a {color:@font;}
}
.documentDescription {margin-left:0;}
	fieldset {padding:0;border-top:1px solid @red !important;}
	}
 .glossaryAbcedaire {
	padding-left:0;
	font-size: 1.0em;
	list-style-type:none;
    	font-family: DGUVMeta-Web-Bold;
	> li {margin-right:1em;display:inline;
		a {color:@red;}
		}
	}
