/* styling for overlays (popups) */

div.overlay {
    width: auto;
    height: auto;
    /* initially overlay is hidden */
    display: none;
    /* some padding to layout nested elements nicely  */
    margin: 1em;
    font-size: 80%;
}
    
div.overlay-iframe,
div.overlay-ajax {
    width: 60%;
    min-height: 100px;
    z-index: 99; /* overlays should be above everything */
}   
      
div.overlay-iframe {
    height: 60%;
}
      
/* default close button positioned on upper-left corner */
div.overlaybg div.close,
div.overlay div.close {
    background-position: -12px -896px ;
    position: absolute;
    left: -14px;
    top: -14px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    opacity:1;
}

.pb-ajax {
    overflow-y: auto;
}
.pb-ajax, .pb-image, .overlay iframe {
    background-color: #fff;
    border: 1px solid #999;
    white-space: normal;
    box-shadow: 0 0 3em 0.5em #666;
    -moz-box-shadow: 0 0 3em 0.5em #666;
    -webkit-box-shadow: 0 0 3em #666;
}

.pb-ajax > div {
    width: 92%;
    padding: 1em;
}

.pb-ajax .documentActions {display:none}

div.overlay div.close span {
    display: block;
    height: 1px;
    margin: -1px 0 0 -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
}

div.overlay textarea { width: 100%; }

/* Hide form elements that don't make sense within an overlay. */
div.overlay .link-parent { display: none; }
div.overlay #createGroup legend { display: none; }
div.overlay #createGroup fieldset { border: none; }

/* lets play nice with paragraphs, links, tabbed pages in overlay e.g. when plone.openid is installed */
div.overlay p {
    margin-bottom: 1em;
}
div.overlay label {
    margin-bottom: 0.25em;
    display:inline-block;
}
div.overlay input[type="checkbox"] {
    margin-bottom: 10px;
}
div.overlay a:hover {
    color: #75ad0a;
}
div.overlay a:hover label{
    cursor: pointer;
}
div.overlay a.selected {
    color: #000000;
}
div.overlay .formTab {
    margin-right:1em;
    font-weight: bold;
}
div.overlay .formTab.lastFormTab {
    margin-right:0em;
}
div.overlay .formControls a {
    display: block;
    margin: 0.75em;
}
