// custom theme

// Stefania Trabucchi

// www.trabucchi.de
// info@trabucchi.de
//

@blue: #004994;
@blueLighter: #e2eef8;
@bluehover: #0099cc;
@blueborder: #99ccff;
@blueextranet :#90aed2;
@white: #FFF;
@font:#333;
@fontLighter:#9a9a9a;
@portlet-font: #424242;
@footer-font: #666;
@icon-padding: 24px;
@baseFontSize: 12px;
@baseLineHeight:20px;
@collapseBg: #ebebeb;

// box colors
@gruen: #51ae31;
@gruenlight: #afca06;

body.userrole-authenticated .row-fluid select[class*="span"] {
  width:80%;
}
.TOC1Digit li:first-child {display:none;}
.TOC1Digit {border-bottom: 1px solid #004994;}

// Helpers : need this only for FF
#clear-space-before-navigation {border:1px solid white;}
.dropdown-menu img {display:none;}
.dropdown-menu a.contenttype-news-item:after {background:none;}
// Basic

a.top { padding-left:10px;text-decoration:none;}

.below-content {padding:0 10px;float:left;clear:both;}
.span6.newsPromotion {
	width: 35.00787401574803% !important;
	}
.span10.newsContent {
	margin-right:5px;
	}

// Fixed navbar
.navbar-inner {
  	min-height: 26px;
  	}
.navbar-inner .container-fluid {
	padding-left:0;
}
.navbar-fixed-top{
  	position: static;
  	}

.navbar .brand {
	font-size: 16px;
	padding: 10px 20px 0 10px;
	}

#globalmenuviewlet .caret {
  border-top-color: @white;
  border-bottom-color: @white;
  border-top: 4px solid @white;
	}

#site-action .caret {
  border-top-color: @blue;
  border-bottom-color: @blue;
  border-top: 4px solid @blue;
}	
.navbar-search { 	
  	margin-top: 15px;
  	}
.navbar-search .searchField {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	}
.navbar-search .searchButton {
	display:none;
}

// navigation
.onpagebottom {
  padding:12px 0;
  float:right;
  clear: both;
  }
.nav-list {
	padding-left: 5px;
	margin-top:30px;
 }
.nav-list a {
	color:@font; 
}
// Plone selectors
.hiddenStructure {
	display: block;
	background: transparent;
	background-image: none;
	border: none;
	height: 0.1em;
	overflow: hidden;
	padding: 0;
	margin: -0.1em 0 0 -0.1em;
	width: 1px;
}
.actionMenuSelected {
background-color:@blueextranet;
color: #666;
}
// New selectors for siguv

// off-canvas

// below is some code reused from http://codepen.io/bradfrost/pen/sjiCv

.wrap {
  position: relative;
  -webkit-transition: all 0.3s ease-out;  
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.wrap.active {
  left: 18em;
}
a.side-menu-link {
  float: left;
  display: block;
  line-height: 30px;
  padding: 6px 0px 12px 20px;
}
.siguv-collapse {
    clear: both;
  	}
// News
h2.enhancedHeadline {
	border-bottom:1px solid @blue;
	background:@blueLighter;
	margin:0;
	padding:0 5px;
	clear:both;
	}
.newsContent h4 {
	border-bottom:1px solid @blue;
	}
h4.newsTitle {
	background:@blueLighter;
	font-size:12px;
	margin:0;
	padding:5px;
	clear:both;
}
.newsItem {
	border-top:1px solid @blueLighter;
}

.newsImage a img {
	padding: 5px 15px 15px 0;
}

.newsData {
	float:left;
	margin:0;
	padding-right:10px;
}
.newsMore a {
	color:@font;
}
.promotionItem {
	width:100%;
	border:1px solid @blueborder;
	margin-bottom:15px;
	}
.promotionItem .portletItem {
  padding-bottom: 4px;
}
.newsPromotion h4.newsTitle {
	border-bottom:1px solid @blueborder;
	}
.promotionItem p {
	padding-left:4px;
	}
// Editor-Modus 
.state-private span {
  color: red !important;
}

// edit-bar 
.edit-menu {
	background-color:#d4d7dd;
	}
// content Plone

h2.headline a {
	color:@font;
	}
#content-core img {
	float:left;
	margin-right:10px;
	}
#search-results-wrapper {padding:0 10px;}
#searchResultsSort:first-child,
#search-results-wrapper img {display:none;}
.searchPage h1 {padding-left:10px;}
input.searchPage{margin-bottom:10px;}
#searchResultsSort {float:right;}
div.ArchetypesField-FileField {float:left;clear:both;}
ul.captioned {
	margin:0 10px 10px 0 !important;
	overflow:hidden;
	}
ul.captioned li {
  margin-left:0 !important;
  }
span.image-caption {
	margin: 0px;
  	background:#e2eef8;
	padding:5px;
	display:block;
	}

.medialinks a {
	position:relative;
	float:right;
	top:-31px;
	right:10px;	
	}
#content > h1,
#content > h2,
#content > h3,
#content > h4,
#content > div {
	padding-left:10px;
	padding-right:10px;
	}

.item p img {
	padding-right:10px;
	padding-bottom:10px;
	}
.listingBar {
	border-top: 1px solid @blue;
	padding: 5px 0px 30px 0;
	float: left;
	width: 100%;
	}
.listingBar span,
.listingBar .next {
	font-weight:bold;
	}
.listingBar .previous {
	margin-right:10px;
	}
.portalMessage {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}
.error span.help-descr,
.error .control-label span {
  color:@font !important;
}
.info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
// Photo Album
/* */
.photoAlbumEntry {
min-height:220px !important;
padding: 0px 6px 0px 9px;
text-align: center;
}
.photoAlbumEntry img {
border: 1px solid #ccc;
display: block;
margin: 0 auto;
}
.photoAlbumEntryWrapper {
margin-bottom: 7px;
}
.photoAlbumEntry a {
display: block;
text-decoration: none;
font-size: 85%;
margin: 16px auto 0px;
}
.photoAlbumFolder {
}
.photoAlbumEntryTitle {
float:left;
color: #76797c;
display: block;
}

// box
.blau100 {
    background-color: #0095db;
}
.blau80 {
    background-color: rgba(0, 149, 219, 0.8);
}
.blau60 {
    background-color: rgba(0, 149, 219, 0.6);
}
.blau40 {
    background-color: rgba(0, 149, 219, 0.4);
}
.blau20 {
    background-color: rgba(0, 149, 219, 0.2);
}
.box {
    height: 165px;
      margin: 20px;
        padding: 10px;
}
.box:hover {
      background-color: rgba(0, 149, 219, 0.3);
}
.box a {
      padding: 0 40px;
        display: inline;
	  color: #ffffff;
	    float: left;
}
.box a:hover {
    color:#f6f6f6 !important;
}
.box-title {
    font-size: bold;
      font-size: 36px;
        float: left;
	  line-height: 36px;
}
// preferences
#personalpreferencesviewlet {
	height: auto !important;
	position: relative;
	top:10px;
}

//pagination inside accordion-collpase
.pagination1 {
	li {list-style-type:none;}
	.btn {margin-top:10px}
	}
.simplePagerNav {
	text-align:center;
	li {display:inline;
	a {padding:6px;}
	}
	.currentPage {background:@blue;
	a {color:white;}
	}
}

// Search
.searchResultPath {
    color: #51ae31;
}
.searchResultHeading {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 2px;
    padding-left: 9px;
    display: block;
}
#portal-searchfacets {
    float: none !important;
    margin-top: 9px;
    margin-left: -7px;
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #99ccff;
    padding: 0;
}
#portal-searchfacets h4 {
    border-bottom: 1px solid #99ccff;
    color: #333333;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.4em;
    margin: 5px 0 !important;
    padding-left: 6px;
}
#portal-searchfacets h5 {
    color: #555555;
    padding-left: 5px;
    font-size: 12px;
    font-weight: bold;
    padding-top: 0;
}
#portal-searchfacets dt{
    padding-left: 5px;
}
#portal-searchfacets dd {
    float: right;
    line-height: normal;
    padding-right: 5px;
    padding-top: 2px;
}

