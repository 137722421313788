// siguv.theme theme
// Stefania Trabucchi
// www.trabucchi.de
// info@trabucchi.de


body {
	background: url("../../../assets/img/background.jpg") repeat-x fixed left top;
	background-color:#6d9ac1;
	}

#wrap {
		background:@blueLighter;
		padding-top:15px;
	.maincolumn   {	
		border-bottom:1px solid @blue;
		background:white;
		}
	}

// END
// fonts
@font-face {
  font-family: DGUVMetaWeb-Bold;
  src: url("../fonts/DGUVMetaWeb-Bold.eot");
}
@font-face {
  font-family: DGUVMetaWeb-Bold;
  src: url("../fonts/DGUVMetaWeb-Bold.woff") format("woff");
}
@font-face {
  font-family: DGUVMetaWeb-Normal;
  src: url("../fonts/DGUVMetaWeb-Normal.eot");
}
@font-face {
  font-family: DGUVMetaWeb-Normal;
  src: url("../fonts/DGUVMetaWeb-Normal.woff") format("woff");
}
@dguv-bold-font-name: "DGUVMetaWeb-Bold";
@dguv-normal-font-name: "DGUVMetaWeb-Normal";

// general
@media (max-width: 1200px) {
.nvaEventTitle .col-md-10 {
        width:100%;
        }
.nvaEventContent.col-md-offset-2 {
        margin-left:0;
        }  
}


.table-responsive caption,
.table-striped caption,
#parent-fieldname-description,
.documentDescription,
h1, h2, h3, h4 { 
  	font-family: @dguv-bold-font-name, sans-serif;
}
.documentByLine {padding:10px 0}
h4 {line-height:1.4em;}
h1 {color:@blue;}
#parent-fieldname-text h1 {
	background-color:@blue;
	border-bottom:1px solid @blue;
	margin:0;
	padding:5px 5px 0 5px;
	color:@white;
	font-size:@font-size-h4;
	}
p, ul, .portlet-column h4, .panel-heading h4 { 
  	font-family: @dguv-normal-font-name, sans-serif;
}
ul.listTypeSquare {
	list-style-type: disc !important;
	padding-left:5px !important;
	li {padding-left:0px;text-indent: -0px !important;}
	}
.panel-heading h4 { 
	color:white;
	}
.documentDescription {
	margin:10px 0;
	}
.newsDescription {
	font-family:@dguv-bold-font-name;
	text-decoration:unterline;
}
.newsImageContainer {    
	float: left;
    	margin-right: 20px;
    	margin-bottom: 10px;
	width:38%; //col-span-4
	}
.actionMenuSelected,
#edit-bar {
		background:darkgrey;
	.state-published {
			background-color:@cyan;
		}
	.active {
			background:ivory;
	}
}
//#main {padding:0 5px 0 0px;}
.breadcrumb {
    padding-top: 5px;
    margin-bottom: 0px;
    margin-right:5px;
    }
.list-group {
    margin-bottom: 10px;
}
a.newsItem:hover,
.media:hover,
.portletEventsPortlet ul:hover {background: @lightGrayBgColor;}
//
a.tile {
    width: 24px;
    height: 24px;
    background-position: -20px -318px;
}
// kurzfassung
.media {
	margin-top:0;
	.description, .bgetem_folder_description {color:@gray-dark;}
}
.media-default {
    margin-top: 0px;
    margin-bottom: 10px;
    border-top: 1px dotted @collapseBg;
}
// viewlets
#viewlet-below-content-body {clear:both;}
// navbar
.navbar-default {
	border:0;
//	margin-right:4px;
	}
.navbar {
    margin-bottom: 0px;
    }
.navbar .nav > li {
	border-right: 1px solid white;
	}
.navbar .nav > li > a {
	padding: 4px 8px 6px;
	}
.navbar .nav > li > {
	a {
		padding: 4px 8px 6px;}
	}
.navbar .nav > li:first-child {
		margin-left:-6px;}
.navbar-default .navbar-inner {
  	background-color: @blue;
  	background-image: none;
	border-color: @blue;
	filter:none;
	}
.navbar-default .brand {
	color: white;
	}
.navbar-fixed-top .navbar-inner, 
.navbar-static-top .navbar-inner {
	border-width: 0;
	}
.navbar-default .nav li:hover,
.navbar-default .nav li.selected,
.navbar-default .nav .active > a,
.navbar-default .nav .active > a:hover,
.navbar-default .nav .active > a:focus {
    background-color: @bluehover;
  	-webkit-box-shadow: none;
  	-moz-box-shadow: none;
  	box-shadow: none;
}
.navbar-default .navbar-toggle {
	float: left;
	margin-left: 15px;
	margin-top: 5px;
	padding:2px;
	margin-bottom:5px;
	line-height:4px;
	border-radius:0;
  	}

@media (max-width: @screen-sm-max) {
.navbar-toggle:after {
    content: 'Rubriken';
    position: relative;
    top: -6px;
    margin-left: 40px;
    color:@white;
	}
}

@media (max-width: 1190px) {
	.navbar-nav > li > a {
	font-size:11px;
	}
}
@media (max-width: 780px) {
	.container-fluid {padding-right:0;padding-left:0;}
	.navbar-nav > li > a {
	font-size:14px;
	}
}
@media (max-width: @screen-md) {
    #side-menu {
        background: @blueLighter;
        border:1px solid #eee;
        -webkit-box-shadow: 3px 3px 5px 0px;
        -moz-box-shadow: 3px 3px 5px 0px;
        box-shadow: 3px 3px 5px 0px;
        ul {
            list-style-type: none;
            display:block;
            margin-right:5px;
            margin-left:-15px !important;
        }
        li.navTreeItem a:hover {color:white !important;}
        .navTreeLevel0 li.navTreeItem:last-child {border-top: 1px solid #004994;}
    }
}
@media (max-width: 768px) {
	.media-object {padding-bottom:10px}
	#viewlet-below-content {display:none;}
        /*
	#side-menu {
		background: @blueLighter; 
		border:1px solid #eee; 
		-webkit-box-shadow: 3px 3px 5px 0px;
		-moz-box-shadow: 3px 3px 5px 0px;
		box-shadow: 3px 3px 5px 0px;
		ul {
			list-style-type: none;
			display:block;
			margin-right:5px;
			margin-left:-15px !important;
		}
		li.navTreeItem a:hover {color:white !important;}
		.navTreeLevel0 li.navTreeItem:last-child {border-top: 1px solid #004994;}
	}
        */

   .navMenu {
	//background:@white;
	line-height:30px;
	font-weight:bold;
	padding-left:10px;
	}
    .navMenu a {
	color:@blue !important;
	}
	li.navTreeItem {
	    border-top: 1px solid @white;
	    padding-top:10px;
    	padding-bottom:10px;
    		a {
    		color:@white;
    		&:hover {color:@white !important;text-decoration:underline}
    		}
    	&:hover {background-color:#094079;}
    	}
	    .navbar .nav>li:first-child {
	    margin-left: 0px !important;
	}
	.navbar .nav>li {
	    border-right: 0 !important;
	}
	.navbar-nav {
		    margin: 0 -3px 0 0;
	li {border-bottom:1px solid #094079}
		}
	.navbar-collapse {
	    padding-left: 0px !important;
    	padding-right: -4px !important;
    }
    .navbar .nav>li a {
    	padding: 10px 8px 10px;
	}
	.btn.btn-info {border:@border-radius-none}
}
@media (max-width: 991px) {
  .fa-plus-square:before {
    padding-right: 10px;
  }
  .portlet-column {margin-top:15px;}
}

@media (max-width: 991px) {
  .fa-plus-square:before {
    padding-right: 10px;
  }
  .portlet-column {margin-top:15px;}
}

//carousel
.carousel-caption {
  padding: 6px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
}

.carousel-caption p {
	margin-right:100px;
	color: #333;
}
.carousel-indicators {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
    margin: 0;
    list-style: none;
}
.carousel-control {
    top: 40%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    font-size: 36px;
    line-height: 30px;
    text-align: center;
    opacity: 0.8;
    filter: alpha(opacity=80);
    z-index:999;
    padding-left:5px;
    padding-right:5px;
}
.carousel-control.left,
.carousel-control.right {background-image:none;}
//portlet

.portlet-column p {
	padding:0 10px;
	}
dl.portletLogin,
dl.portletCollection,
.portlet-column .portlet {
	background:@white;
	border:1px solid @collapseBg;
	margin:0px 10px 15px 10px;
}
dl.portletLogin .portletHeader,
dl.portletCollection .portletHeader,
.portlet-column h4 {
	color:@bluehover;
	font-weight:bold;
	line-height:1.4em;
	padding-left:10px;
	padding-right:10px;
	border-bottom:1px solid @collapseBg;
	margin:5px 0 !important;
	a {color:@bluehover !important;}
}
.portletStichwort,
.portletNews > div,
.portletCollection > div {
	margin-left:5px;
	}

.portletNews div >div,
.portletCollection div > div {
	line-height: @line-height-computed;
	padding-right: 5px;
	margin-bottom:5px;
	}
dl.portletLogin,
dl.portletCollection {
	.portletHeader {font-size: @font-size-h4;}
	.portletItem {
	margin-left: 13px;
    	text-indent: -13px;
	margin-right:10px;
	margin-bottom:10px;
	}
}
dl.portletLogin {	
	label {margin-left:10px}
	}
.newsPromotion ul,
.portlet-column ul {
	list-style-type: none;
	padding-left:0;
	margin-left:0;
	margin-bottom:0px;
	}

.portletRss a,
.portlet-column h4 a,
.portletNews a,
.portletCollection a,
.portlet-column p,
.portlet-column p a,
.portlet-column ul li a {
	color:@font;
	line-height: @line-height-computed;
}
.list-group.portletItem li,
.portletRss > div{
	padding-right:10px;
	margin-left: 21px;
    	text-indent: -12px;
}
.portletItem a.tile {
    width: 24px;
    height: 24px;
    background-position: -20px -318px;
    padding-left:12px;
}
// breadcrumbs
#portal-breadcrumbs span {
	color:@fontLighter;
	}
.breadcrumb {
    border-radius: 0px;
    margin-right: 10px;
    margin-bottom: 5px !important;
    padding-left:10px !important;
    }
// site-action nav
#site-action {
	padding-right:0px;
}
#site-action .navbar .nav > li > a {
	color: @blue !important;
	padding:0 !important;
	}
#site-action .navbar .nav > .active > a {
	color: @blue;
	}
#site-action .nav  {
	margin-bottom:0;
	}
#site-action ul  {
	margin:10px 10px;
	}
li.divider-vertical:last-child {
	display:none;
}
.divider-vertical {
  border-left: 1px solid @blue;
  border-right: 1px solid @blueLighter;
  height: 12px;
  margin: 2px 4px;
}
.navbar .divider-vertical {
  border-left: 1px solid @blue;
  border-right: 1px solid @blueLighter;
  height: 12px;
  margin: 2px 4px;
}

// personal-tools
#portal-personaltools {
	position: absolute;
	z-index: 3;
	top: -3px;
	padding: 0.1em 0.5em;
	list-style-type: none;
	}
#portal-personaltools dd {
	position:absolute;
	}
@media (max-width: 768px) {
	#portal-personaltools {
	top:-20px;
	left:86% !important;
	}
}
// code tab
        .pattern-math {
            padding-top: 80px; padding-bottom: 80px;
        }
        
        .slider-ver .ct > .ct-viewport {
            padding: 0;
            background-color: #ddd;
            border: 6px solid #fff; 
        }
        .slider-ver .ct-pag .ct-pagitem {
            padding: 25px;
            margin: 0 0 1px 6px;
            background-color: #ddd;
            color: #999;
            font-size: 1.1em;
        }
        .slider-ver .ct-pagitem.ct-cur {
            background-color: #fff;
            color: #333;
        }

        .ct-pagitem.ct-cur:after {
            display: block;
            content: '';
            position: absolute;
            width: 0; height: 0;
            border: 8px solid transparent;
        }

        @media only screen and (min-width: 780px) {
            .ct-pagitem.ct-cur:after {
                left: -9px; top: 50%;
                margin-top: -8px;
                border-left-color: #fff;
            }
        }
        @media only screen and (min-width: 1px) and (max-width: 767px) {
            .tab-desc { display: none; }
            .slider-ver .ct-pag .ct-pagitem {
                padding: 20px;
                margin: 6px 1px 0 0;
                font-size: 1em;
            }
            .ct-pagitem.ct-cur:after {
                left: 50%; top: -9px;
                margin-left: -8px;
                border-top-color: #fff;
            }
        }
// slider tab
#myCarousel .nav a small {
    display:block;
}
#myCarousel .nav {
	background:#eee;
}
#myCarousel .nav a {
    border-radius:0px;
}
#tabCarousel h3 {color:@bluehover;margin-top:0;}

//Portlet manager

.managePortletsLink {padding:10px}
.eventDetails {background:#f5f5f5;margin:10px 0;}

h3.newsDescription {
   margin-top: 0;
}

.newsItemTile {

  & > a {
    display:block;
    padding-bottom: 1em;
	p, .nvaEventTitleDate {color:@font;} 
 }

  .nvaEventTitle {
    margin-top: 2px;
    margin-bottom: 2em;

    h3 {
      margin-top:0;
    }
  }

  .ivcal {
      margin-top: -1em;
      text-align: right;
  }

}
